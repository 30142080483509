$(document).ready(function(){
  animateHeader();
  mobileNavMaxHeight();
  shareTrigger();

  // detect touch devices
  function is_touch_device() {
   return 'ontouchstart' in window        // works on most browsers
    || navigator.maxTouchPoints;       // works on IE10/11 and Surface
  };

  if ( is_touch_device() ) {
    $('html').addClass('touch')
  } else {
    $('html').addClass('no-touch')
  }


  // initialize owl carousels
  $('.testimonial-carousel').owlCarousel({
    loop:true,
    nav:true,
    navText:[
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
    dots:false,
    autoplay:true,
    autoplayHoverPause:true,
    autoplayTimeout : 7000,
    items:1
  });

	// check to see if < 2 upcoming events to prevent loop other wise events get duplicated. Though this is somewhat intended
	// this is a know quirck and might may or may not be fixed - https://github.com/OwlCarousel2/OwlCarousel2/issues/1503#issuecomment-279404321
	// There is a workaround just make sure you specify what carousel if you have > 1 carousel on the page to apply the fix!!!!
	// Fix - https://github.com/OwlCarousel2/OwlCarousel2/issues/1589#issuecomment-275207039
  $('.events-carousel').owlCarousel({
    loop: $(this).find('.owl-carousel.events-carousel .item').size() > 2,
    nav:true,
    navText:[
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
    dots:true,
    autoplay:true,
    autoplayHoverPause:true,
    smartSpeed:500,
    autoplaySpeed:800,
    responsive:{
        0:{
            items:1
        },
        768:{
            items:2
        }
    }
  });

  // Scroll to Gravity Form on failed validation.
  if ( $( '.gform_wrapper' ).hasClass( 'gform_validation_error' ) ) {
	  var scroll_to = $( '.validation_error').offset().top - $( 'header' ).height();
	  $(document).scrollTop( scroll_to );
  }

  $(window).load(function(){
    directoryBioWidth();
	});


  $(window).scroll(function(){
    animateHeader();
	});


  $(window).resize(function(){
    animateHeader();
    mobileNavMaxHeight();
    directoryBioWidth();
	});

});


// get window width
function getWindowWidth() {
  var windowWidth = $(window).width();
  return windowWidth;
}

// get window height
function getWindowHeight() {
  var windowHeight = $(window).height();
  return windowHeight;
}

// set mobile nav breakpoint
function getMobileBreakpoint() {
  var mobileBreakpoint = 1000;
  return mobileBreakpoint;
}


// sticky header function
function animateHeader() {
  var header = $('header'),
      shrinkOn = 25,
      windowWidth = getWindowWidth(),
      mobileBreakpoint = getMobileBreakpoint();

  if (windowWidth >= mobileBreakpoint) {
    if ($(document).scrollTop() >= shrinkOn ) {
      header.addClass('sticky');

    } else {
      header.removeClass('sticky');
    }
  } else {
    header.removeClass('sticky');
  }
}


// mobile nav max height
function mobileNavMaxHeight() {
  var windowWidth = getWindowWidth(),
      windowHeight = getWindowHeight(),
      mobileBreakpoint = getMobileBreakpoint(),
      mainNav = $('#mainNav');

  if (windowWidth < mobileBreakpoint) {
    mainNav.css('max-height', windowHeight - 50);
  } else {
    mainNav.css('max-height', '');
  }
}


// sticky header function
function directoryBioWidth() {
  var windowWidth = getWindowWidth(),
      directoryItem = $('.directory-item', '.directory-list'),
      bioContainer = '.directory-bio';


  directoryItem.each(function(){
    var bioContainerOffset = $(this).find('.directory-item-content').offset();

    if (windowWidth >= 768 ) {
      $(this).find(bioContainer).width(windowWidth).css('margin-left', bioContainerOffset.left*-1);

    } else {
      $(bioContainer).width('');
      $(bioContainer).css('margin-left', '');
    }
  });

  // Bootstrap collapse workaround for bio accordions to close other open bios when a new button is clicked (only on desktop)
  var cpaGroup = $('.directory-list.cpa'),
      staffGroup = $('.directory-list.staff');

  if (windowWidth >= 768) {
    staffGroup.on('show.bs.collapse','.collapse', function() {
      staffGroup.find('.collapse.in').collapse('hide');
    });
  }

  if (windowWidth >= 960) {
    cpaGroup.on('show.bs.collapse','.collapse', function() {
      cpaGroup.find('.collapse.in').collapse('hide');
    });
  }
}



// share this animation

function shareTrigger() {
  var shareTrigger = $('.show-share-items'),
      shareItems = $('.share-items'),
      shareContainer = $('.share');

  $(shareTrigger).click(function(ev){
    var shareItemGroup = $(this).next('.share-items');

    if ( $(this).hasClass('active')) {
      $(this).removeClass('active');
      $(shareItemGroup).removeClass('active');

    } else {
      $(shareTrigger).removeClass('active');
      $(shareItems).removeClass('active');
      $(this).addClass('active');
      $(shareItemGroup).addClass('active');
    }
  });

  $(shareContainer).click(function(ev){
    ev.stopPropagation();
  });

  $('html').click(function(){
    if ($(shareItems).hasClass('active')) {
      $(shareItems).removeClass('active');
      var sfired = false;
      $(shareItems).one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
        if ( !sfired ) {
          sfired = true;
          $(shareTrigger).removeClass('active');
        }
      });
    }
  });
}


// Expanding search box (desktop only)
$(document).ready(function(){
  var submitIcon = $('.searchbox-icon', 'header');
  var inputBox = $('.searchbox-input', 'header');
  var searchBox = $('.searchbox', 'header');
  var isOpen = false;

  submitIcon.click(function(){
    if(isOpen == false){
      $('#nav-search').addClass('search-open');
      searchBox.addClass('searchbox-open');
      inputBox.focus();
      isOpen = true;
    } else {
		if ( '' !== $( '.searchbox-input' ).val() ) {
			$( '.searchbox form').trigger( 'submit' );
		} else {
			$('#nav-search').removeClass('search-open');
			searchBox.removeClass('searchbox-open');
			inputBox.focusout();
			isOpen = false;
		}
    }
  });

  submitIcon.mouseup(function(){
    return false;
  });

  searchBox.mouseup(function(){
    return false;
  });

  $(document).mouseup(function(){
    if(isOpen == true){
      $('.searchbox-icon').css('display','block');
      submitIcon.click();
    }
  });
});
